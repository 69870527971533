export const anchorTagFormat = (anchorText: string) =>
    anchorText
        .toLowerCase()
        .trim()
        .replace(/[ -]+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/^[0-9_-]*/g, '');

export const shortName = (name: string, maxLength: number) => {
    if (name.length > maxLength) {
        return `${name.slice(0, maxLength)}…`;
    }
    return name;
};
