
import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { ArticleH2BlokInterface } from '~/types/articlePage';
import { anchorTagFormat } from '~/utils/filters';

export default defineComponent({
    name: 'ArticleH2Component',
    props: {
        blok: {
            type: Object as PropType<ArticleH2BlokInterface>,
            required: true,
        },
        stickyZipCta: { type: Boolean, default: false },
    },
    computed: {
        anchorTag() {
            const anchorText = this.blok.anchorTag || this.blok.text;
            return anchorTagFormat(anchorText);
        },
    },
});
